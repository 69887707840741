import { Fragment } from "react";
import { CSSProperties } from "styled-components";
import { CAMPAIGN_INFO } from "../../constants/KeyConstant";
import Header from "../../assets/images/header.png";
const Footer = () => {
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");

  const footerDiv: CSSProperties = {
    backgroundImage: "url(" + Header + ")",
    width: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    minHeight: "25vh",
  };
  return (
    <Fragment>
      <div className="footer" style={footerDiv}></div>
    </Fragment>
  );
};

export default Footer;
