import Cookie from "js-cookie";

const SetCookie = (cookiename: any, usrin: any) => {
  let onehour = new Date(new Date().getTime() + 3600 * 1000 * 72);
  Cookie.set(cookiename, usrin, {
    expires: onehour,
    secure: true,
    sameSite: "strict",
    path: "/",
  });
};

export default SetCookie;
