import "../assets/css/wheelresult.css";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/wheelresult.css";
import GIFTS from "../assets/images/bg-giftss.png";
import TITLE from "../assets/images/title-guide.png";
import { Col, Modal, Spinner } from "react-bootstrap";
import BG_POPUP from "../assets/images/bg-popup.png";
import TITLE_POPUP from "../assets/images/title-popup.png";
import INPUT_CUSTOM from "../assets/images/input-custom-img.png";
import CLOSE_WR from "../assets/images/close.png";
import { CAMPAIGN_APPCODE, CAMPAIGN_INFO } from "../constants/KeyConstant";
import GetCookie from "../hooks/GetCookie";
import { campaignServices } from "../api/Services/CampaignService";
import SetCookie from "../hooks/SetCookie";
import close from "../assets/images/close.png";
import axios from "axios";
import Social from "../components/Social";
import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";
import L_B from "../assets/images/l-b.png";
import R_B from "../assets/images/r-b.png";
import BG_BOTTOM_POPUP from "../assets/images/Popup-Bottom.png";
import BG_TOP_POPUP from "../assets/images/Popup-Top.png";
import IMG_YELLOW from "../assets/images/Vector.png";
import INPUT_HC from "../assets/images/input-hc.png";
import BTN_CODE from "../assets/images/btn-code.png";
import BG_POPUP_HC from "../assets/images/bg-popup-hc.png";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};

const Home = () => {
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const [seeMore, setSeeMore] = useState(false);
  const [popupCode, setPopupCode] = useState(false);
  let voucher: any = JSON.parse(localStorage.getItem("voucher_bigc") || "{}");
  const [gift, setGift] = useState<any>(voucher);
  const [loading, setloading] = useState<boolean>(true);
  const [popupMessage, setpopupMessage] = useState("");
  const [color, setColor] = useState("red");
  const [openPopupMessage, setopenPopupMessage] = useState<boolean>(false);
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setSeeMore(!seeMore);
    setSeeMore(true);
  };
  const onRegister = () => {
    setCode("");
    setPopupCode(true);
  };
  const [code, setCode] = useState("");
  const onInputCode = (e: any) => {
    console.log(e.target.value);
    setCode(e.target.value);
  };
  const closePopup = () => {
    setPopupCode(false);
    setSeeMore(false);
  };
  const closePopupGiftError = () => {
    setopenPopupMessage(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setloading(true);
  }, []);
  useEffect(() => {
    setloading(false);
  }, [gift]);

  const getIP = async () => {
    const a = await axios.get(
      "https://ipgeolocation.abstractapi.com/v1/?api_key=10c186258733484e812b16506581652d"
    );
    if (a?.data?.country === "Vietnam") {
    } else {
      navigate("/page403");
    }
  };
  const redirectToPrizes = () => {
    window.scrollTo(0, 0);
    navigate("/" + appCode + "/gifts-page");
  };
  const handleInputNewPhoneName = () => {
    const a = GetCookie("user");
    setWaiting(true);
    console.log(a);
    let data = JSON.parse(GetCookie("user"));
    console.log(data);
    const data_receive = {
      params: {
        url: data.params.url,
        session_id: voucher?.session_id,
      },
    };
    console.log(data_receive);
    campaignServices
      .getReceiveGift(data_receive)
      .then((res: any) => {
        console.log(res);
        localStorage.clear();
        navigate("/" + appCode);
        // setReceiveGift(res);
        // setReceiveGiftList(res.gift_list);
      })
      .catch((e) => {
        console.log(e);
        setpopupMessage(e);
        setopenPopupMessage(true);
      })
      .finally(() => {
        setWaiting(false);
      });
  };
  const handleNoInputCodeAgain = () => {};
  const handleInputCodeAgain = () => {
    setWaiting(true);
    let data = JSON.parse(GetCookie("user"));

    console.log(data);
    data.params["code"] = code;
    data.params["session_id"] = voucher?.session_id;
    console.log(data);
    campaignServices
      .checkPhone_v1(data)
      .then((response: any) => {
        console.log(response);
        localStorage.setItem("voucher_bigc", JSON.stringify(response));
        SetCookie("user", JSON.stringify(data));
        setGift(response);
        setWaiting(false);
        setloading(false);
        // navigate("/" + appCode + "/wheelresult");
        setPopupCode(false);
      })
      .catch((err): any => {
        console.log(err);
        setopenPopupMessage(true);
        setpopupMessage(err);
      })
      .finally(() => {
        setWaiting(false);
      });
  };
  return (
    <>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <div
            className={`${
              appCode === "pgtheloc" ? "background-yomost" : "background-tai"
            }`}
          >
            <div style={{ position: "relative", top: 0 }}>
              <img
                src={cpData?.logo_image_header}
                className="image-home"
                style={{ position: "relative", top: 0 }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "20vh",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  color: "white",
                }}
              >
                <div
                  className="title-gift"
                  style={{
                    width: "100%",
                    padding: "0 20px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "30px",
                    fontWeight: "600",
                    color: `${cpData?.home_url_color}`,
                  }}
                >
                  XIN CHÚC MỪNG
                </div>{" "}
                <div
                  className="name-gift"
                  style={{ color: cpData?.text_color }}
                >
                  {" "}
                  Bạn đã trúng phần quà là
                </div>
                <div
                  className="name-gift"
                  style={{ color: `${cpData?.home_url_color}` }}
                >
                  {gift?.gift}
                </div>
                <div
                  className="checksum-gift"
                  style={{ color: cpData?.text_color }}
                >
                  {gift?.gift_checksum}
                </div>
                <div
                  className="img-gift"
                  style={{
                    position: "relative",
                    marginTop: "-20px",
                    marginBottom: "-60px",
                  }}
                >
                  <img
                    src={GIFTS}
                    className=""
                    style={{
                      position: "relative",
                      top: "-60px",
                      width: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      opacity: "1",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "8%",
                      transform: "translate(-50%)",
                    }}
                  >
                    <img
                      src={gift?.gift_image}
                      className=""
                      style={{
                        position: "relative",
                        top: "-30px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    padding: "0 10px",
                    fontSize: "10px",
                    lineHeight: "19px",
                    position: "relative",
                    top: "-68px",
                    zIndex: 100000,
                  }}
                  className="containerNotify__background"
                >
                  <div
                    className="containerNotify__background-lis"
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      color: cpData?.text_color,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: gift?.conditions_receiving_prize,
                    }}
                  >
                    {/* <ul
                      style={{
                        textAlign: "left",
                        marginLeft: "-15px",
                        paddingRight: "px",
                      }}
                    >
                      <li>
                        Đối với giải thưởng là thẻ quà tặng điện tử: Quý khách
                        sẽ được nhận được link voucher thông qua tin nhắn điện
                        thoại của mình trong vòng 5 phút.
                      </li>
                      <li style={{ marginTop: "2px" }}>
                        Đối với giải thưởng là hiện vật: Hotline{" "}
                        <span style={{ color: "#FDE791" }}>02836222363</span> sẽ
                        liên hệ để thu thập thông tin trao giải trong vòng{" "}
                        <span style={{ color: "#FDE791" }}>7-10 </span>
                        ngày tới.
                      </li>
                      <li style={{ marginTop: "2px" }}>
                        Phần thưởng sẽ được trao trong vòng{" "}
                        <span style={{ color: "#FDE791" }}>20-25</span> ngày
                        tính từ lúc lấy thông tin trao thưởng thành công.
                      </li>
                      <li>
                        *Hình ảnh quà tặng mang tính chất minh họa. Giá trị sản
                        phẩm thay đổi theo giá thị trường
                      </li>
                    </ul> */}

                    {/* )} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: `${appCode === "hc2024" ? "column" : "row"}`,
                    marginTop: "-60px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      zIndex: "999",
                      textAlign: "center",
                      padding: "0 20px",
                      width: `${appCode === "hc2024" ? "100%" : "300px"}`,
                    }}
                  >
                    <Col>
                      <button
                        className={"btn-register-active"}
                        style={{
                          height: "53px",
                          backgroundColor: cpData?.button_label_color,
                          position: "relative",
                          borderColor: appCode === "hc2024" ? "#F6E192" : "",
                        }}
                        onClick={onRegister}
                      >
                        NHẬP TIẾP MÃ
                        {appCode === "hc2024" ? (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                left: "-10px",
                              }}
                            >
                              <img src={L_B} />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: "-10px",
                              }}
                            >
                              <img src={R_B} />
                            </div>
                          </>
                        ) : null}
                      </button>
                    </Col>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0 20px",
                      width: `${appCode === "hc2024" ? "100%" : "300px"}`,
                      marginTop: `${appCode === "hc2024" ? "10px" : "0px"}`,
                    }}
                  >
                    <Col>
                      <div
                        style={{
                          position: "relative",
                          zIndex: "999",
                          height: "53px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "11px",
                          border: "1px solid white",
                          backgroundColor:
                            appCode === "hc2024" ? "#F6E192" : "#ffffff",
                        }}
                      >
                        <div
                          className="wheelresult-btn-nhanqua"
                          style={{
                            backgroundColor:
                              appCode === "hc2024" ? "#FCF8EA" : "",
                          }}
                          onClick={() => redirectToPrizes()}
                        >
                          NHẬN QUÀ
                          {appCode === "hc2024" ? (
                            <>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: "-10px",
                                }}
                              >
                                <img src={L_B} />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: "-10px",
                                }}
                              >
                                <img src={R_B} />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
            <img src={cpData?.logo_image_footer} className="footer-bottom" />
          </div>

          <Modal show={popupCode} onHide={closePopup}>
            {appCode === "hc2024" ? (
              <Fragment>
                <div className="bg_img-hc">
                  <div style={{ position: "relative", top: "1px" }}>
                    <img
                      src={BG_TOP_POPUP}
                      style={{ width: "100%", zIndex: 0 }}
                    />
                    <div className="check-animation">
                      <img
                        src={IMG_YELLOW}
                        style={{
                          width: "100%",
                          bottom: 0,
                          padding: "0 12%",
                          position: "absolute",
                          zIndex: "10",
                        }}
                      />
                      <label
                        style={{
                          width: "100%",
                          bottom: "0px",
                          padding: "0 12%",
                          position: "absolute",
                          zIndex: "30",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{ position: "relative", zIndex: "-100000" }}
                        >
                          <img src={INPUT_HC} />
                          <input
                            id="input-type"
                            type="text"
                            onChange={(e) => onInputCode(e)}
                            style={{
                              position: "absolute",
                              zIndex: "100000",
                              top: "50%",
                              width: "87%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                            }}
                            placeholder="Nhập mã dự thưởng"
                            className="input-hidden-hc"
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                  <div style={{ position: "relative", top: 0, zIndex: "20" }}>
                    <img src={BG_BOTTOM_POPUP} style={{ width: "100%" }} />
                    <label
                      style={{
                        position: "absolute",
                        bottom: "40px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        color: "transparent",
                      }}
                      onClick={
                        code ? handleInputCodeAgain : handleNoInputCodeAgain
                      }
                    >
                      <img src={BTN_CODE} />
                      <div
                        style={{
                          position: "absolute",
                          color: "red",
                          marginTop: "15px",
                          fontWeight: 700,
                        }}
                      >
                        NHẬP MÃ DỰ THƯỞNG
                      </div>
                    </label>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="popup-box-code">
                  <img
                    src={BG_POPUP}
                    style={{
                      width: "100%",
                      position: "relative",
                      zIndex: "50",
                    }}
                  />
                </div>
                <div className="content-popup-box-code">
                  <div>
                    <img src={TITLE_POPUP} />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    Vui lòng nhập mã dự thưởng
                  </div>
                  <label
                    style={{
                      marginTop: "30px",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <div style={{ position: "relative", zIndex: "-100000" }}>
                      <img src={INPUT_CUSTOM} />
                      <input
                        id="input-type"
                        type="text"
                        onChange={(e) => onInputCode(e)}
                        style={{
                          position: "absolute",
                          zIndex: "100000",
                          top: "50%",
                          width: "87%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        placeholder="Nhập mã dự thưởng"
                        className="input-hidden"
                      />
                    </div>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "120px",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <div>
                      <button
                        className=""
                        style={{
                          width: "120px",
                          height: "50px",
                          borderRadius: "10px",
                          backgroundColor: "#E03022",
                          color: "#fff",
                          border: "3px solid #fff",
                        }}
                        onClick={handleInputNewPhoneName}
                      >
                        SĐT KHÁC
                      </button>
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <button
                        className=""
                        style={{
                          width: "120px",
                          height: "50px",
                          borderRadius: "10px",
                          backgroundColor: `${code ? "#285C5C" : "#bdbdbd"}`,
                          color: "#fff",
                          border: "3px solid #fff",
                        }}
                        onClick={
                          code ? handleInputCodeAgain : handleNoInputCodeAgain
                        }
                      >
                        NHẬP MÃ
                      </button>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </Modal>
          <Modal show={openPopupMessage}>
            <div className="popup-box">
              <>
                <img
                  className="close-btn"
                  src={close}
                  onClick={closePopupGiftError}
                />
                <div className="popup-message">
                  <h3 className="popup-header">Thông báo</h3>
                  <p>{popupMessage}</p>
                </div>
                <button className="popup-btn-ok" onClick={closePopupGiftError}>
                  Đồng ý
                </button>
              </>
            </div>
          </Modal>
          <Modal show={seeMore} onHide={closePopup}>
            <div className="popup-box-code">
              {appCode === "hc2024" ? (
                <img src={BG_POPUP_HC} style={{ width: "100%" }} />
              ) : (
                <img src={BG_POPUP} style={{ width: "100%" }} />
              )}
              <div>
                {appCode === "hc2024" ? null : (
                  <img
                    src={TITLE}
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                )}
              </div>
            </div>

            <div className="containerNotify__background content-popup-box-content">
              <div
                className="containerNotify__background-list"
                style={{
                  marginTop: "80px",
                  textAlign: "justify",
                  fontSize: "14px",
                }}
              >
                <ul style={{ marginLeft: "-3px", paddingRight: "8px" }}>
                  <li>
                    <span style={{ fontWeight: "800" }}>QUÀ VẬT LÝ:</span>{" "}
                    Hotline 028 362 22 363 sẽ liên hệ để thu thập thông tin trao
                    giải trong 1-3 ngày tới
                    <br />
                  </li>
                  <li>
                    <span style={{ fontWeight: "800" }}>QUÀ VOUCHER:</span> Đã
                    được gửi đến tin nhắn của Quý Khách
                  </li>
                </ul>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "800",
                    padding: "0 10px",
                  }}
                >
                  Vui lòng nhận cuộc gọi và giữ lại tin nhắn HOẶC màn hình trúng
                  giải
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                left: "50%",
                top: "30px",
                marginLeft: "-15px",
              }}
              onClick={closePopup}
            >
              <img
                src={CLOSE_WR}
                style={{
                  width: "40px",
                }}
              />
            </div>
          </Modal>
          <Social />
          <Modal show={waiting}>
            <div
              style={{
                position: "absolute",
                top: "25vh",
                left: "50%",
                zIndex: "50000",
                transform: "translateX(-50%)",
              }}
            >
              <Spinner animation="border" role="status" variant="light">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Home;
