export default function InternalServerError() {
  return (
    <div className="bg-denied">
      <div>
        <div className="bg-logo">LOGO</div>
        <div className="bg-logo-ig relative">
          {/* <img src={ADENIED} className="img-denied" /> */}
          <span className="relative z-0 locate_start">5</span>
          <span className="relative z-10 locate_between">0</span>
          <span className="relative z-20 locate_last">0</span>
        </div>
        <div className="bg-title">Internal server error</div>
        <div className="bg-content">
          Sorry for the inconvenience. Please report this message and include
          the following information to us. Thank you very much!
          <br />
          <br />
          <div>
            <span className="url-text">URL:</span> https://xxx
            <br /> <span className="url-text">Server:</span>{" "}
            gbridge.033001233211.us44
            <br /> <span className="url-text">Date:</span> 2024/12/21 21:30:04
          </div>
        </div>
      </div>
    </div>
  );
}
