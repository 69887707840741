import { useState } from "react";

import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";
import "../assets/css/noaccess.css";
import DESKTOP_TAI from "../assets/images/desktop-tai.png";
import DESKTOP_LOC from "../assets/images/desktop-loc.png";

import { isMobileDevice } from "../utils/deviceModel";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};
const Register = () => {
  const [loading, setloading] = useState<boolean>(false);
  const [color, setColor] = useState("red");
  let isMobile = isMobileDevice();
  const appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  const background_tai: CSSProperties = {
    background: "url(" + DESKTOP_TAI + ")",
    height: "100vh",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  };
  const background_loc: CSSProperties = {
    background: "url(" + DESKTOP_LOC + ")",
    height: "100vh",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  };
  const backgroundMobile_tai: CSSProperties = {
    background: "url(" + DESKTOP_TAI + ")",
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const backgroundMobile_loc: CSSProperties = {
    background: "url(" + DESKTOP_TAI + ")",
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : isMobile ? (
        <div
          style={{
            backgroundColor: `${
              appCode === "pgthetai" ? "#E5AF3D" : "#E84D38"
            }`,
          }}
        >
          {/* <div
            style={
              appCode === "pgthetai"
                ? backgroundMobile_tai
                : backgroundMobile_loc
            }
          ></div> */}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: `${
              appCode === "pgthetai" ? "#E5AF3D" : "#E84D38"
            }`,
          }}
        >
          {/* <div
            style={appCode === "pgthetai" ? background_tai : background_loc}
          ></div> */}
        </div>
      )}
    </>
  );
};

export default Register;
