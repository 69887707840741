export function isNullOrUndefinedOrEmpty(value?: any): boolean {
  return value === undefined || value === null || value.length === 0
}

export function isNotNullAndNotUndefined(value?: any): boolean {
  return value !== undefined && value !== null && value.trim().length !== 0
}

export function isNotNullAndNotUndefinedArray(value?: any): boolean {
  return value !== undefined && value !== null && value.length !== 0
}

export function isArrayEmpty(arr?: any): boolean {
  return isNullOrUndefinedOrEmpty(arr) || !Array.isArray(arr) || arr.length === 0
}

export function isStringEmpty(str: any): boolean {
  return typeof str === 'string' && (isNullOrUndefinedOrEmpty(str) || str.trim().length === 0)
}

export function isObjectEmpty(obj: any = {}): boolean {
  return typeof obj === 'object' && (isNullOrUndefinedOrEmpty(obj) || Object.keys(obj).length === 0)
}
