import ADENIED from "../assets/images/403.jpg";

export default function AccessDenied() {
  return (
    <div className="bg-denied">
      <div>
        <div className="bg-logo">LOGO</div>
        <div className="bg-logo-ig relative">
          {/* <img src={ADENIED} className="img-denied" /> */}
          <span className="relative z-0 locate_start">4</span>
          <span className="relative z-10 locate_between">0</span>
          <span className="relative z-20 locate_last">3</span>
        </div>
        <div className="bg-title">Access Denied</div>
        <div className="bg-content">
          You do not have permission to access this resource. <br />
          The request could not be fulfilled by the server due to insufficient
          access privileges. If you believe this is an error, please contact the
          website administrator for assistance
        </div>
      </div>
    </div>
  );
}
