import { Fragment, useEffect, useState } from "react";
import { Col, Container, Form, Row, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../assets/css/home.css";
import close from "../assets/images/close.png";
import "react-toastify/dist/ReactToastify.css";
import { isNotNullAndNotUndefined } from "../utils/empty-util";
import { CAMPAIGN_APPCODE, CAMPAIGN_INFO } from "../constants/KeyConstant";
import "../assets/css/checkphone.css";
import { campaignServices } from "../api/Services/CampaignService";
import { CAMPAIGN_INFOR } from "../api/configURL";
import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";
import { MetaTags } from "react-meta-tags";
import GetCookie from "../hooks/GetCookie";
import Social from "../components/Social";
import BG_BOTTOM_POPUP from "../assets/images/Popup-Bottom.png";
import BG_TOP_POPUP from "../assets/images/Popup-Top.png";
import IMG_YELLOW from "../assets/images/Vector.png";
import INPUT_HC from "../assets/images/input-hc.png";
import BTN_CODE from "../assets/images/btn-code.png";
import L_B_B from "../assets/images/l-b-b.png";
import R_B_B from "../assets/images/r-b-b.png";
import SetCookie from "../hooks/SetCookie";
import L_B from "../assets/images/l-b.png";
import R_B from "../assets/images/r-b.png";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};

const IntroTheTaiLoc = () => {
  const [waiting, setWaiting] = useState(false);
  const [isActive2, setisActive2] = useState(false);
  const [isActive3, setisActive3] = useState(true);
  const [loading, setloading] = useState<boolean>(true);
  const [showmore, setshowmore] = useState(false);
  const [customer_name, setcustomer_name] = useState("");
  const [errRegister, setErrRegister] = useState("");
  const [popupMessage, setpopupMessage] = useState("");
  const [openPopupMessage, setopenPopupMessage] = useState<boolean>(false);
  const [isTruePhone, setisTruePhone] = useState<boolean>(false);
  const [phone, setphone] = useState("");
  const [popupMessageValidate, setpopupMessageValidate] = useState("");
  const [redirectHomePage, setredirectHomePage] = useState(false);
  const [code_gift, setCode_gift] = useState("");
  const [color, setColor] = useState("red");
  const navigate = useNavigate();
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  console.log(appCode);
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");

  const [dataCheckIP, setCheckIP] = useState<any>(undefined);
  const [dataCampaign, setDataCampaign] = useState<any>();
  console.log(dataCheckIP);
  useEffect(() => {
    setloading(true);
    let url = "";
    url = "https://scratchcard.mvc.com.vn/" + appCode;
    const data = {
      params: { url: url },
    };
    console.log(data);
    const a = GetCookie("IP");
    console.log(typeof a);
    campaignServices
      .getCampaignInfo(data)
      .then((response: any) => {
        console.log(response);
        setDataCampaign(response);
        localStorage.setItem(CAMPAIGN_INFOR, JSON.stringify(response));
        cpData = response;
        setloading(false);
      })
      .catch((e) => {
        console.log(e);
        setpopupMessage(e);
        setopenPopupMessage(true);
      });
  }, []);

  const onCheckBox2 = () => {
    setisActive2(!isActive2);
  };
  const handleLink = () => {
    window.open("https://privacypolicy.pg.com/vi/", "WindowName", "noopener");
  };
  const checkRegister = () => {
    setErrRegister(`Vui lòng ấn xem thêm và bấm “Tôi đồng ý” để tiếp tục`);
  };
  const onRegister = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const check = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
    if (
      // isNotNullAndNotUndefined(code_gift) &&
      isNotNullAndNotUndefined(customer_name) &&
      isNotNullAndNotUndefined(phone) &&
      phone.length === 10
    ) {
      const a = check.test(phone);
      if (check.test(phone) === false) {
        setpopupMessageValidate("Vui lòng nhập đúng định dạng số điện thoại");
        setopenPopupMessage(true);
        setphone("");
      } else {
        // setisTruePhone(true);
        // setopenPopupMessage(true);
        setIsOpenEnterCode(true);
      }
    } else {
      setpopupMessage(
        "Vui lòng nhập đầy đủ thông tin Tên khách hàng và số điện thoại!"
      );
      setopenPopupMessage(true);
      setredirectHomePage(false);
      setloading(false);
    }
  };
  const closePopupValidate = () => {
    setopenPopupMessage(false);
    setpopupMessageValidate("");
  };
  const closePopup = () => {
    setopenPopupMessage(false);
    setisTruePhone(false);
    navigate("/" + appCode);
  };
  const [isOpenEnterCode, setIsOpenEnterCode] = useState(false);
  const btnAcceptSubmit = (e: any) => {
    localStorage.setItem("phone", phone);
    setIsOpenEnterCode(true);
  };
  const validateData = (value: string, key: string) => {
    const check = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
    if (key === "phone" && value.length !== 10) {
      if (check.test(value)) {
        setpopupMessageValidate("Vui lòng nhập đúng định dạng số điện thoại");
      } else {
        setpopupMessageValidate(
          "Vui lòng nhập số điện thoại đúng định dạng 10 số!"
        );
      }
      setopenPopupMessage(true);
      setphone("");
    }
  };
  const handleInputPhone = (e: any) => {
    setphone(e.target.value);
  };
  const handleInputCode = (e: any) => {
    setCode_gift(e.target.value);
  };
  const handleCheckCode = () => {
    setWaiting(true);
    setopenPopupMessage(false);
    setisTruePhone(false);
    const data = {
      params: {
        customer_name: customer_name,
        phone: phone,
        url: "https://scratchcard.mvc.com.vn/" + appCode,
        code: code_gift,
      },
    };

    campaignServices
      .checkPhone(data)
      .then((response: any) => {
        console.log(response);
        SetCookie("user", JSON.stringify(data));
        localStorage.setItem("voucher_bigc", JSON.stringify(response));
        navigate("/" + appCode + "/wheelresult");
      })
      .catch((err): any => {
        console.log(err);
        setopenPopupMessage(true);
        setpopupMessage(err);
      })
      .finally(() => {
        setWaiting(false);
      });
  };
  return (
    <>
      <MetaTags>
        <title>{cpData?.name}</title>
      </MetaTags>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className={""}>
          <div style={{ position: "relative", top: 0 }}>
            <img
              src={cpData?.logo_image_header}
              className="image-home"
              style={{ position: "relative", top: 0, zIndex: "100" }}
            />
            <img
              src={cpData?.background_img}
              className=""
              style={{
                width: "100%",
                position: "relative",
                marginTop: "-140px",
                zIndex: "50",
              }}
            />
            <div style={{ position: "absolute", top: "26vh", zIndex: "150" }}>
              <div
                style={{
                  padding: "0 10px",
                  textAlign: "center",
                  color: cpData?.text_color,
                }}
              >
                Vui lòng cung cấp thêm thông tin chính xác để nhận quà.
              </div>
              <Container fluid="md" style={{ paddingTop: "3px" }}>
                <Form style={{ marginTop: "10px" }}>
                  <Row
                    style={{
                      marginTop: "5px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        placeholder="Nhập tên khách hàng"
                        onChange={(e) => setcustomer_name(e.target.value)}
                        value={customer_name}
                      ></input>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      marginTop: "10px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        type="tel"
                        placeholder="Nhập số điện thoại"
                        onChange={(e) => handleInputPhone(e)}
                        onBlur={(e) => validateData(e.target.value, "phone")}
                        value={phone}
                      ></input>
                    </Col>
                  </Row>

                  {/* <Row
                    style={{
                      marginTop: "10px",
                      width: "100vw",
                      padding: "0 15px",
                    }}
                  >
                    <Col xs={24} md={24}>
                      <input
                        className="input-form"
                        placeholder="Nhập mã dự thưởng"
                        onChange={(e) => handleInputCode(e)}
                        value={code_gift}
                      ></input>
                    </Col>
                  </Row> */}
                  <Row
                    style={{
                      color: `${
                        appCode === "pgtheloc" ? "#F6E192" : "#f63440"
                      }`,
                      display: "flex",
                      justifyContent: "center",
                      padding: "0 40px",
                    }}
                  >
                    {errRegister}
                  </Row>
                </Form>
              </Container>
              <Row style={{ marginTop: "15px", padding: "0 20px 0 23px" }}>
                <Col
                  xs={12}
                  md={12}
                  className="title-checkbox"
                  style={{ color: cpData?.text_color }}
                >
                  <div className="confirm">
                    <span>
                      <div className="containerNotify__backgrounds">
                        <div className="containerNotify__backgrounds-lis">
                          <div className="checkbox-tc">
                            <input
                              type="checkbox"
                              className="input-checkbox  "
                              placeholder="Nhập mã OTP"
                              id="label2"
                              onClick={(e) => onCheckBox2()}
                            ></input>

                            <label className="label-checkbox1" htmlFor="label2">
                              Tôi đồng ý{" "}
                            </label>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            Là một phần của chương trình này, P&G sẽ chia sẻ dữ
                            liệu cá nhân của bạn với đơn vị cung cấp dịch vụ
                            được P&G ủy quyền, các công ty thuộc tập đoàn P&G,
                            và/hoặc chuyển dữ liệu cá nhân của bạn đến một địa
                            điểm bên ngoài Việt Nam cho các mục đích xử lý được
                            mô tả bên dưới. P&G không bán dữ liệu cá nhân của
                            bạn cho bên thứ ba.
                            <br />
                            <div style={{ marginTop: "8px" }}>
                              {" "}
                              Bạn quyết định việc đánh dấu vào các ô bên dưới và
                              đồng ý cho chúng tôi sử dụng dữ liệu cá nhân của
                              bạn. Tuy nhiên, lựa chọn từ chối của bạn có thể
                              ảnh hưởng đến việc nhận sản phẩm/dịch vụ mà chúng
                              tôi cung cấp theo chương trình, cũng như giới hạn
                              trải nghiệm mà bạn có được khi tham gia chương
                              trình này.
                            </div>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            • P&G Việt Nam và đơn vị cung cấp dịch vụ của P&G có
                            thể xử lý dữ liệu cá nhân của tôi nhằm mục đích đánh
                            giá điều kiện tôi tham chương trình khuyến mại, liên
                            hệ trao giải thưởng, quản lý và báo cáo kết quả của
                            chương trình theo quy định của pháp luật. Nếu bạn từ
                            chối đồng ý, bạn sẽ không thể tham gia chương trình
                            này.
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            Chính sách bảo mật của chúng tôi có sẵn tại{" "}
                            <span
                              className="wrap"
                              onClick={handleLink}
                              style={{
                                color: `${cpData?.home_url_color}`,
                              }}
                            >
                              https://privacypolicy.pg.com/vi/
                            </span>{" "}
                            và áp dụng cho việc thu thập, sử dụng và tiết lộ dữ
                            liệu cá nhân của bạn. Bằng cách đánh dấu vào các ô ở
                            trên, bạn đồng ý cho P&G được thu thập, sử dụng, xử
                            lý và chuyển dữ liệu cá nhân của bạn theo Chính sách
                            quyền riêng tư của chúng tôi.
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  padding: "0 40px",
                }}
              >
                <Col>
                  {isActive2 && isActive3 === true ? (
                    <button
                      className={"btn-register-active"}
                      onClick={(e) => onRegister(e)}
                      style={{
                        position: "relative",
                        height: "53px",
                        backgroundColor: cpData?.button_label_color,
                        borderColor:
                          appCode === "hc2024" ? "#F6E192" : "#ffffff",
                      }}
                      disabled={!isActive2 || loading || !isActive3}
                    >
                      TIẾP TỤC
                      <>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: "-10px",
                            // width: "125px",
                          }}
                        >
                          <img src={L_B} />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: "-10px",
                          }}
                        >
                          <img src={R_B} />
                        </div>
                      </>
                    </button>
                  ) : (
                    <div
                      className="btn-register"
                      style={{ position: "relative" }}
                      onClick={() => checkRegister()}
                    >
                      TIẾP TỤC
                      <>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: "-10px",
                          }}
                        >
                          <img src={L_B_B} />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: "-10px",
                          }}
                        >
                          <img src={R_B_B} />
                        </div>
                      </>
                    </div>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  padding: "0 40px",
                }}
              >
                <Col>
                  <a
                    className="btn-tlct"
                    style={{
                      color: "#CC2023",
                      textDecoration: "none",
                    }}
                    href={cpData?.tc_url}
                  >
                    THỂ LỆ CHƯƠNG TRÌNH
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100px",
                      }}
                    >
                      <img src={L_B} />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: "100px",
                      }}
                    >
                      <img src={R_B} />
                    </div>
                  </a>
                </Col>
              </Row>
            </div>
          </div>

          <Modal show={openPopupMessage}>
            <div className="popup-box">
              {isTruePhone ? (
                <>
                  <img className="close-btn" src={close} onClick={closePopup} />
                  <div className="popup-message">
                    <div style={{ marginTop: "10px" }} className="popup-phone">
                      {phone}
                    </div>
                    <p>
                      Có phải số điện thoại chính xác của bạn không? Chúng tôi
                      sẽ gửi voucher đến số điện thoại này.
                    </p>
                  </div>
                  <button
                    className="popup-btn-ok"
                    onClick={(e) => btnAcceptSubmit(e)}
                  >
                    Đồng ý
                  </button>
                </>
              ) : popupMessageValidate ? (
                <>
                  <img
                    className="close-btn"
                    src={close}
                    onClick={closePopupValidate}
                  />
                  <div className="popup-message">
                    <h3 className="popup-header">Thông báo</h3>
                    <p>{popupMessageValidate}</p>
                  </div>
                  <button className="popup-btn-ok" onClick={closePopupValidate}>
                    Đồng ý
                  </button>
                </>
              ) : (
                <>
                  <img className="close-btn" src={close} onClick={closePopup} />
                  <div className="popup-message">
                    <h3 className="popup-header">Thông báo</h3>
                    <p>{popupMessage}</p>
                  </div>
                  <button className="popup-btn-ok" onClick={closePopup}>
                    Đồng ý
                  </button>
                </>
              )}
            </div>
          </Modal>
          <Modal show={isOpenEnterCode}>
            <Fragment>
              <div className="bg_img-hc">
                <div style={{ position: "relative", top: "1px" }}>
                  <img
                    src={BG_TOP_POPUP}
                    style={{ width: "100%", zIndex: 0 }}
                  />
                  <div className="check-animation">
                    <img
                      src={IMG_YELLOW}
                      style={{
                        width: "100%",
                        bottom: 0,
                        padding: "0 12%",
                        position: "absolute",
                        zIndex: "10",
                      }}
                    />
                    <label
                      style={{
                        width: "100%",
                        bottom: "0px",
                        padding: "0 15%",
                        position: "absolute",
                        zIndex: "30",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ position: "relative", zIndex: "-100000" }}>
                        <img src={INPUT_HC} />
                        <input
                          id="input-type"
                          type="text"
                          onChange={(e) => handleInputCode(e)}
                          value={code_gift}
                          style={{
                            position: "absolute",
                            zIndex: "100000",
                            top: "50%",
                            width: "87%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                          }}
                          placeholder="Nhập mã dự thưởng"
                          className="input-hidden-hc"
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div style={{ position: "relative", top: 0, zIndex: "20" }}>
                  <img src={BG_BOTTOM_POPUP} style={{ width: "100%" }} />
                  <label
                    style={{
                      position: "absolute",
                      bottom: "40px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      color: "transparent",
                    }}
                    onClick={() => handleCheckCode()}
                  >
                    <img src={BTN_CODE} />
                    <div
                      style={{
                        position: "absolute",
                        color: "red",
                        marginTop: "18px",
                        fontWeight: 700,
                      }}
                    >
                      NHẬP MÃ DỰ THƯỞNG
                    </div>
                  </label>
                </div>
              </div>
            </Fragment>
          </Modal>
          <Social />
        </div>
      )}
      <Modal show={waiting}>
        <div
          style={{
            position: "absolute",
            top: "25vh",
            left: "50%",
            zIndex: "50000",
            transform: "translateX(-50%)",
          }}
        >
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Modal>
    </>
  );
};

export default IntroTheTaiLoc;
