import React from "react";
import "../assets/css/cpnsocial.css";
import { CAMPAIGN_INFO } from "../constants/KeyConstant";

const Social = () => {
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const onClickCallPhone = (e) => {
    e.preventDefault();
    const phone = cpData.hotline.replace(/\D/g, "");
    window.location.href = `tel:${phone}`;
  };
  const onClickInboxMail = (e) => {
    e.preventDefault();
    window.open("https://zalo.me/3442793812218426302", "noopener");
  };
  return (
    <div className="social-container">
      <div className="social-phone" onClick={(e) => onClickCallPhone(e)}></div>
      <div className="social-mail" onClick={(e) => onClickInboxMail(e)}></div>
    </div>
  );
};

export default Social;
