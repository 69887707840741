export default function NotFound404() {
  return (
    <div className="bg-denied">
      <div>
        <div className="bg-logo">LOGO</div>
        <div className="bg-logo-ig relative">
          {/* <img src={ADENIED} className="img-denied" /> */}
          <span className="relative z-0 locate_start">4</span>
          <span className="relative z-10 locate_between">0</span>
          <span className="relative z-20 locate_last">4</span>
        </div>
        <div className="bg-title">Page not found</div>
        <div className="bg-content">
          Sorry, we could not find the page you are looking for.
        </div>
      </div>
    </div>
  );
}
