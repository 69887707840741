import { URL_API_BE_ODOO, URL_SEND_WITH_API } from "../constants/URLConstant";
import request from "./request";
// End cau hinh
export class crudApi {
  static getCampaign(url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "GET",
    });
  }
  static getReceiveGift(data, url) {
    return request({
      url: url,
      method: "POST",
      data: data,
    });
  }
  static postToServerAction(data, url) {
    return request({
      url: url,
      method: "POST",
      data: data,
    });
  }

  static findAll(data, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: data,
    });
  }
  static findAllGet(param, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "get",
      params: param,
    });
  }
  static create(data, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: data,
    });
  }
  static findById(id, url) {
    return request({
      url: URL_API_BE_ODOO + url + id,
      method: "get",
    });
  }
  static checkPhoneAndSessionId(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }
  static checkPhone(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }

  static validateCode(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "POST",
      data: params,
    });
  }
  static checkInfoUser(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }
  static checkOTP(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }
  static getGift(url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "get",
    });
  }
  static getListWin(page, limit, url, appcode) {
    return request({
      url:
        URL_API_BE_ODOO +
        url +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&url=" +
        URL_SEND_WITH_API +
        appcode,
      method: "get",
    });
  }
  static getListGiftForWheel(url) {
    return request({
      url: URL_API_BE_ODOO + url + "?url=" + URL_SEND_WITH_API + "/",
      method: "get",
    });
  }
  static submitReceipt(data, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: data,
    });
  }
  static submitDrawGift(data, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: data,
    });
  }
  static update(data, id, url) {
    return request({
      url: URL_API_BE_ODOO + url + id,
      method: "put",
      data: data,
    });
  }

  static deleteOne(id, url) {
    return request({
      url: URL_API_BE_ODOO + url + id,
      method: "delete",
    });
  }

  // linhlt code
  static getCampaignInfo(param, url) {
    return request({
      url: URL_API_BE_ODOO + url + "?url=" + param,
      method: "get",
    });
  }

  static sendTopup(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }

  static countDraw(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }

  // list option qua
  static callPostAPI(params, url) {
    return request({
      url: URL_API_BE_ODOO + url,
      method: "post",
      data: params,
    });
  }
}
