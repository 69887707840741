export const CAMPAIGN_APPCODE  = "CAMPAIGN_APPCODE";
export const CAMPAIGN_PGCODE   = "CAMPAIGN_PGCODE";
export const CAMPAIGN_INFO     = "CAMPAIGN_INFO";

export const GCS_RESULT        = "GCS_RESULT";

export const CHECKPHONE_CUSTOM_NAME = "customer_name"
export const SUBMIT_RECEIPT_GSURL = "gsutil_url";
export const SUBMIT_RECEIPT_PUBLICURL = "public_url";
export const SUBMIT_RECEIPT_OCR_RESULT = "ocr_result";


export const WHEEL_PHANTHUONG  = "WHEEL_PHANTHUONG";
export const WHEEL_LUOTQUAY  = "WHEEL_LUOTQUAY";
export const WHEEL_HAS_LUOTQUAY  = "WHEEL_HAS_LUOTQUAY";

export const SUBMIT_RECEIPT_TIME = "receipt_datetime"
export const SUBMIT_RECEIPT_REQUEST_ID = "request_id"