import {
  createRouterMiddleware,
  createRouterReducerMapObject,
  ReduxRouter,
} from "@lagunovsky/redux-react-router";
import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const store = configureStore({
  reducer: createRouterReducerMapObject(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(routerMiddleware),
});

root.render(
  <Provider store={store}>
    <ReduxRouter history={history}>
      <App />
    </ReduxRouter>
  </Provider>
);
reportWebVitals();
