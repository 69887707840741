import axios from "axios";
import { URL_API_BE_ODOO } from "../constants/URLConstant";

// create an axios instance
const service = axios.create({
  baseURL: URL_API_BE_ODOO, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 90000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // do something with request error
    //console.log(error) // for debug
    return Promise.reject(error);
  }
);
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  (response) => response,
  (response) => {
    const res = response.data;

    //   // if the custom code is not 20000, it is judged as an error.
    //   if (res.code !== 20000) {
    //     Message({
    //       message: res.message || 'error',
    //       type: 'error',
    //       duration: 5 * 1000
    //     })

    //     // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //     if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //       // to re-login
    //       MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //         confirmButtonText: 'Re-Login',
    //         cancelButtonText: 'Cancel',
    //         type: 'warning'
    //       }).then(() => {
    //         store.dispatch('user/resetToken').then(() => {
    //           location.reload()
    //         })
    //       })
    //     }
    //     return Promise.reject(res.message || 'error')
    //   } else {
    //     return res
    //   }
  },
  (error) => {
    //console.log(error)
    return Promise.reject(error);
  }
);

export default service;
