import notfound from "../assets/images/404.jpg";
const NotFound = () => {
    return (
            <>
                <img style={{width: "100%", height: "100vh"}} src={notfound}/>
            </>
        )

}
export default NotFound;
