import GetCookie from "../../hooks/GetCookie";
import { axiosInstance } from "../configURL";

export const campaignServices = {
  getCampaignInfo: (data) => {
    return axiosInstance.post("/unfix/campaign_info", data, {
      headers: {
        "Content-Type": "application/json",
        TOKEN: "lcq22qp13pua3tk4r3d79ev4s38vda",
        // ADDRESS_IP: GetCookie("IP"),
      },
    });
  },
  getReceiveGift: (data) => {
    return axiosInstance.post("/unfix/receive_gift", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  checkPhone: (data) => {
    return axiosInstance.post("/unfix/validate_code", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  checkPhone_v1: (data) => {
    return axiosInstance.post("/unfix/validate_code", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
