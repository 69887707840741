import { UAParser } from "ua-parser-js";
export let userAgent = window.navigator.userAgent,
  platform = window.navigator.platform,
  macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  iosPlatforms = ["iPhone", "iPad", "iPod"],
  os = "";

export const DEVICE = {
  MACOS: "Mac OS",
  WINDOWS: "Windows",
  LINUX: "Linux",
  IOS: "iOS",
  ANDROID: "Android",
};
export function getOS() {
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.MACOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = DEVICE.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = DEVICE.ANDROID;
  } else if (!os && /Linux/.test(platform)) {
    os = DEVICE.LINUX;
  }
  return os;
}

export function getDeviceInfo() {
  let parser = new UAParser();
  let info = parser.getResult();
  return info;
}

export function isMobileDevice() {
  let os = getOS();
  console.log(os);
  return os === DEVICE.ANDROID || os === DEVICE.IOS;
}

export function isSupportedBrowser() {
  let deviceInfo = getDeviceInfo();
  localStorage.setItem("detect", deviceInfo.browser.name);
  console.log(deviceInfo.browser.name);
  let a = false;
  if (
    deviceInfo.browser.name.includes("WebKit") ||
    deviceInfo.browser.name.includes("Android Browser") ||
    deviceInfo.browser.name.includes("ZALO")
  ) {
    a = true;
  } else {
    a = true;
  }
  return a;
  // return (
  //   deviceInfo.browser.name === "Chrome" ||
  //   deviceInfo.browser.name === "Safari" ||
  //   deviceInfo.browser.name === "Mobile Safari" ||
  //   deviceInfo.browser.name === "Samsung Browser" ||
  //   deviceInfo.browser.name === "MIUI Browser" ||
  //   deviceInfo.browser.name === "Facebook" ||
  //   deviceInfo.browser.name.includes("WebKit") ||
  //   deviceInfo.browser.name.includes("Android Browser")
  // );
}

export function isLocalhost() {
  const host = window.location.origin;
  return host.includes("localhost");
}
