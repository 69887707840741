import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { CSSProperties } from "styled-components";
import { apiOdoo } from "./api/apiOdoo";
import "./App.css";
import { CAMPAIGN_APPCODE } from "./constants/KeyConstant";
import { routersNotAuth, routesAuth } from "./routes/routes";
import {
  isLocalhost,
  isMobileDevice,
  isSupportedBrowser,
} from "./utils/deviceModel";
import { ToastContainer } from "react-toastify";
const App: React.FC = (): JSX.Element => {
  const appCode = window.location.pathname.split("/")[1];
  const host = window.location.origin;
  localStorage.setItem(CAMPAIGN_APPCODE, appCode.toLowerCase());

  let access = false;
  let mobileDevice = isMobileDevice();
  let supportedBrowser = isSupportedBrowser();
  if ((mobileDevice && supportedBrowser) || isLocalhost()) {
    access = true;
  }
  const noAuth = useRoutes(routersNotAuth);
  const auth = useRoutes(routesAuth);

  if (access) {
    return (
      <>
        <ToastContainer />
        <div className="App">{auth}</div>
      </>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <div className="App">{noAuth}</div>
      </>
    );
  }
};

export default App;
