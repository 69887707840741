import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { CSSProperties } from "styled-components";
import { crudApi } from "../api/crudApi";
import "../assets/css/prizes.css";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SyncLoader from "react-spinners/SyncLoader";
import Social from "../components/Social";
import {
  CAMPAIGN_APPCODE,
  CAMPAIGN_INFO,
  CAMPAIGN_PGCODE,
  WHEEL_PHANTHUONG,
} from "../constants/KeyConstant";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  opacity: "0.8",
  background: "white",
  textAlign: "center",
  height: "100vh",
  position: "absolute",
  width: "100%",
  paddingTop: "48vh",
};
const Home = () => {
  const navigate = useNavigate();
  const appCode = localStorage.getItem(CAMPAIGN_APPCODE);
  let pgCode = localStorage.getItem(CAMPAIGN_PGCODE);
  let cpData: any = JSON.parse(localStorage.getItem(CAMPAIGN_INFO) || "{}");
  const cpPhanThuongData: any = JSON.parse(
    localStorage.getItem(WHEEL_PHANTHUONG) || "{}"
  );
  // them bien
  const [data, setData] = useState<any[]>([]);
  const [maximum_of_page, setmaximum_of_page] = useState(10);
  const [total_gift_curr_page, settotal_gift_curr_page] = useState(5);
  const [total_of_page, settotal_of_page] = useState(30);
  const [current_page, setcurrent_page] = useState(1);
  const [total_gift, settotal_gift] = useState(0);
  const [loading, setloading] = useState(false);
  const [color, setColor] = useState("red");

  const background: CSSProperties = {
    background: "url(" + cpData.background_img + ")",
    height: "100vh",
  };

  const getListPrize = (curPage, limit) => {
    setloading(true);
    crudApi
      .getListWin(curPage, limit, "ocr/list_win", appCode)
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.result.meta.status === true
        ) {
          let list = response.data.result.data.list_prizes;
          setData(list);
          settotal_of_page(response.data.result.data.total_of_page);
          setmaximum_of_page(response.data.result.data.maximum_of_page);
          setcurrent_page(response.data.result.data.current_page);
          settotal_gift(response.data.result.data.total_gift);
          settotal_gift_curr_page(
            response.data.result.data.total_gift_curr_page
          );
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        console.log("Lỗi khi gọi api");
      });
  };

  useEffect(() => {
    getListPrize(current_page, maximum_of_page);
  }, []);

  const nextChangePage = () => {
    let page = current_page + 1;
    setcurrent_page(page);
    getListPrize(page, maximum_of_page);
  };
  const backChangePage = () => {
    let page = current_page === 1 ? 1 : current_page - 1;
    setcurrent_page(page);
    getListPrize(page, maximum_of_page);
  };

  return (
    <>
      {loading ? (
        <SyncLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div style={background}>
          <Social />
          <Header />
          <Container fluid="md">
            <div className="data-list">
              <div className="title-data center">
                Danh sách khách hàng tham gia
              </div>
              <div className="table-body-scroll">
                <table className="table table-striped">
                  <tbody>
                    <tr className="table-th">
                      <th>Ngày</th>
                      <th colSpan={2}>SĐT</th>
                      <th>Phần quà</th>
                    </tr>
                    {data?.map((row) => {
                      return (
                        <tr key={row.index}>
                          <td> {row.date_time}</td>
                          <td colSpan={2}> {row.phone}</td>
                          <td>{row.gift}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <table className="table table-striped">
                <tfoot>
                  <tr>
                    <td colSpan={4}>
                      <div className="prizes-footer">
                        <button className="prizes-btn-prev">
                          <i
                            className="material-icons"
                            style={{ width: "16px" }}
                          >
                            arrow_back
                          </i>
                          Trang trước
                        </button>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <button
                          onClick={() => nextChangePage()}
                          className="prizes-btn-next"
                        >
                          Trang sau
                          <i
                            className="material-icons"
                            style={{ width: "16px" }}
                          >
                            arrow_forward
                          </i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
