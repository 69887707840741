import NoAccessBrowser from "../pages/NoAccessBrowser";
import Prizes from "../pages/Prizes";
import WheelResult from "../pages/WheelResult";
import CheckPhone from "../pages/CheckPhone";
import NotFound from "../pages/NotFound";
import IntroTheTaiLoc from "../pages/IntroTheTaiLoc";
import GiftPage from "../pages/GiftPage";
import AccessDenied from "../PageError/AccessDenied403";
import NotFound404 from "../PageError/NotFound404";
import InternalServerError from "../PageError/InternalServerError";
import Test from "./Test";

export const routesAuth = [
  // { path: "/:id", exact: true, element: <Test /> },
  // { path: "/", exact: true, element: <Test /> },
  { path: "/notfound", exact: true, element: <NotFound /> },
  { path: "/page403", exact: true, element: <AccessDenied /> },
  { path: "/page404", exact: true, element: <NotFound404 /> },
  { path: "/page500", exact: true, element: <InternalServerError /> },
  { path: "/:appcode", exact: true, element: <IntroTheTaiLoc /> },
  { path: "/hc2024", exact: true, element: <CheckPhone /> },

  { path: "/:appcode/gifts-page", exact: true, element: <GiftPage /> },
  {
    path: "/:appcode/wheelresult",
    exact: true,
    element: <WheelResult />,
  },
  {
    path: "/:appcode/prizes",
    exact: true,
    element: <Prizes />,
  },
];

export const routersNotAuth = [
  { path: "*", exact: true, element: <NoAccessBrowser /> },
];
